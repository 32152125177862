<template>
  <div>
    <v-toolbar class="ma-2 header" short elevation="1">
      <v-layout align-center justify-space-between>
        <v-flex lg1>
          <!-- <v-icon v-if="xsOnly" size="23" class="px-1"> mdi-arrow-left </v-icon>
          <v-btn color="darkenGrey--text transparent" elevation="0" v-else>
            <v-icon left> mdi-arrow-left </v-icon>
            <span> Back </span>
          </v-btn> -->
        </v-flex>
        <v-flex lg3 class="text-center">
          <span class="darkenGrey--text font-weight-black"> Tenants </span>
        </v-flex>
        <v-flex lg1 class="text-end">
          <v-btn class="darkenGrey white--text text-capitalize" @click="createNewTenant"> Create </v-btn>
        </v-flex>
      </v-layout>
    </v-toolbar>
    <v-layout row wrap align-center class="ma-1">
      <v-flex lg3 xs12 v-for="(tenant, index) in tenants" :key="index">
        <v-card class="ma-1 cursor-pointer" @click="$router.push(`/tenants/${tenant._id}`)">
          <v-card-text class="px-2 py-3 text-center">
            <span class="body-2 font-weight-black"> {{ tenant.name }} </span>
          </v-card-text>
          <v-card-text class="pa-2">
            {{ tenant }}
          </v-card-text>
        </v-card>
      </v-flex>
    </v-layout>
    <v-dialog persistent v-model="newTenantDialog" fullscreen hide-overlay transition="dialog-bottom-transition" no-click-animation>
      <v-card tile>
        <v-card-title class="py-2 px-3 dialog-header darkenGrey white--text text-capitalize">
          <span> new tenant </span>
          <v-spacer></v-spacer>
          <v-btn color="white" text class="mr-n3" @click="newTenantDialog = false;"> close </v-btn>
        </v-card-title>
        <v-card-text class="pa-0">
          <v-form ref="tenantForm">
            <v-container>
              <v-row>
                <v-col cols="12" lg="3">
                  <v-text-field outlined dense hide-details label="name" v-model="tenantObj.name"
                  :rules="$_requiredValidation" validate-on-blur></v-text-field>
                </v-col>
                <v-col cols="12" lg="3">
                  <v-text-field outlined dense hide-details label="username" v-model="tenantObj.username"
                  :rules="$_requiredValidation" validate-on-blur></v-text-field>
                </v-col>
                <v-col cols="12" lg="3">
                  <v-text-field outlined dense hide-details label="password" v-model="tenantObj.password"></v-text-field>
                </v-col>
              </v-row>
            </v-container>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="success" class="text-capitalize" width="90" @click="createOrUpdateHandler"> create </v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
export default {
  data () {
    return {
      newTenantDialog: false,
      tenants: [],
      tenantObj: {
        role: 2
      }
    }
  },
  beforeMount () {
    const query = [{
      $match: { role: 2 }
    }]
    this.$_execute('post', 'users/query', query)
      .then(({ data }) => {
        this.tenants = data
        // this.tenants = []
      })
      .catch((err) => this.$_handleApiError(err))
    // events
    // const eventsQuery = {
    //   start: this.$moment().startOf('month').format('YYYY-MM-DDTHH:mm'),
    //   end: this.$moment().endOf('month').format('YYYY-MM-DDTHH:mm')
    // }
    // this.$_execute('post', 'events/filter', eventsQuery)
    //   .then(({ data }) => {
    //     console.log(data)
    //   })
    //   .catch((err) => this.$_handleApiError(err))
  },
  methods: {
    createNewTenant () {
      this.$refs.tenantForm && this.$refs.tenantForm.reset()
      this.newTenantDialog = true
    },
    createOrUpdateHandler () {
      if (this.$refs.tenantForm && this.$refs.tenantForm.validate()) {
        this.$_execute('post', 'signup', this.tenantObj)
          .then(() => {
            this.$root.$emit('snackbar', { color: 'success', text: 'New tenant created successfully!' })
          })
          .catch((err) => this.$_handleApiError(err))
      } else this.$root.$emit('snackbar', { color: 'error', text: 'Please check all the required fields!' })
    }
  }
}
</script>
<style>
  .header .v-toolbar__content {
    padding: 0 10px !important;
  }
</style>
