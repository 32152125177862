<template>
  <div>
    <v-toolbar class="ma-2 header" short elevation="1">
      <v-layout align-center justify-space-between>
        <v-flex lg1 xs1>
          <v-icon v-if="xsOnly" size="23" class="px-1" @click="$router.push('/tenants')" color="darkenGrey"> mdi-arrow-left </v-icon>
          <v-btn color="darkenGrey--text transparent" elevation="0" @click="$router.push('/tenants')" v-else>
            <v-icon left> mdi-arrow-left </v-icon>
            <span> Back </span>
          </v-btn>
        </v-flex>
        <v-flex lg10 xs10 class="text-center">
          <span class="darkenGrey--text font-weight-black"> {{ tenantObj.name }} </span>
        </v-flex>
        <v-flex lg1 xs1 class="text-end">
          <!-- <v-btn class="darkenGrey white--text text-capitalize" @click="createNewTenant"> Create </v-btn> -->
        </v-flex>
      </v-layout>
    </v-toolbar>
    <v-layout row wrap align-center class="ma-2">
      <v-flex lg8 class="pr-lg-1">
        <v-card height="100%">
          <!-- {{ $moment($moment(tenantObj.lastLogin, 'YYYY-MM-DDTHH:mm')).isUTC() }} -->
          {{ $moment($moment.utc(tenantObj.lastLogin, 'YYYY-MM-DDTHH:mm').toDate()).format('DD.MM.YYYY HH:mm') }}
          <!-- {{ tenantObj.lastLogin }} -->
        </v-card>
      </v-flex>
      <v-flex lg4 class="pl-lg-1">
        <v-card :class="xsOnly ? 'mt-1' : ''">
          <v-card-title class="grey darken-4 py-3 px-3 pa-lg-4">
            <v-container fluid class="pa-0">
              <v-row>
                <v-col cols="12" lg="12" xl="12" sm="12" md="12" :class="[$vuetify.breakpoint.sm ? '' : 'text-center', 'px-0 py-2']" align-self="center">
                  <v-layout align-center class="mt-xl-2 pa-1 pa-lg-0" justify-center>
                    <v-flex :class="[payload.refreshBtn ? 'mr-n1' : 'ml-n4', 'text-center']" lg8 md7 xl8 sm7 xs9>
                      <v-btn class="mx-1 mr-lg-1 mr-xl-0" outlined fab color="white" x-small @click="$refs.calendar.prev()" elevation="1">
                        <v-icon size="18"> mdi-chevron-left </v-icon>
                      </v-btn>
                      <span class="font-weight-medium white--text subtitle-1 mx-0 mx-lg-1 ml-xl-3 mr-xl-2 cursor-pointer user-select-none"
                      @click="getToday">
                        {{ $refs.calendar ? $refs.calendar.title : '' }}
                      </span>
                      <v-btn class="mx-1" outlined fab color="white" x-small @click="$refs.calendar && $refs.calendar.next()" elevation="1">
                        <v-icon size="18"> mdi-chevron-right </v-icon>
                      </v-btn>
                    </v-flex>
                  </v-layout>
                </v-col>
              </v-row>
            </v-container>
          </v-card-title>
          <v-card-text class="pa-1">
            <v-calendar ref="calendar" :events="events" event-color="#202020" @change="getEvents" v-model="calendarValue"></v-calendar>
          </v-card-text>
        </v-card>
      </v-flex>
    </v-layout>
  </div>
</template>
<script>
export default {
  data () {
    return {
      moduleId: this.$route.params.id,
      tenantObj: {},
      // events
      events: [],
      payload: {},
      calendarValue: ''
    }
  },
  beforeMount () {
    this.getTenantDetails()
  },
  methods: {
    getTenantDetails () {
      const query = [{
        $set: {
          _id: { $toString: '$_id' }
        }
      }, {
        $match: {
          _id: this.moduleId,
          role: 2
        }
      }]
      this.$_execute('post', 'users/query', query)
        .then(({ data }) => {
          this.tenantObj = data[0] || {}
        })
        .catch((err) => this.$_handleApiError(err))
    },
    getToday () {
      this.calendarValue = ''
      // console.log(this.$moment().startOf('m').format('YYYY-MM-DD'))
      // this.getEvents({ start: '', end: '' })
    },
    getEvents ({ start, end }) {
      this.events = []
      const from = this.$moment(start.date, 'YYYY-MM-DD').subtract(start.weekday, 'd').format('YYYY-MM-DDT00:01')
      const to = this.$moment(end.date, 'YYYY-MM-DD').add((6 - end.weekday), 'd').format('YYYY-MM-DDT23:59')
      this.$_execute('post', `events/filter?user=${this.moduleId}`, { start: from, end: to })
        .then(async ({ data }) => {
          await data.map((item) => {
            item.start = this.$_utcToLocal(item.start, 'YYYY-MM-DDTHH:mm', 'YYYY-MM-DD HH:mm')
            item.end = this.$_utcToLocal(item.end, 'YYYY-MM-DDTHH:mm', 'YYYY-MM-DD HH:mm')
            item.name = 'booked'
            // item.color = '#202020'
          })
          this.events = data
        })
        .catch((err) => this.$_handleApiError(err))
    }
  }
}
</script>
<style>
  .v-event div strong {
    display: none !important;
  }
</style>
